import { FORCE_ENABLE_MAESTRO_LOGIN, INVITE_TOKEN, NO_GDPR, POST_AUTH_REDIRECT, SITE_SLUG, TYPEFORM_FIRST_SSU_LOGIN_FORM_ID } from 'config';
import { LOCATION_CHANGE } from 'connected-react-router';
import find from 'lodash/find';
import { Channel, eventChannel } from 'redux-saga';
import { all, call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { getAuthenticationMap, getSiteId, getSiteTags, getSiteSlug } from 'services/app/selectors';
import { loadGA } from 'services/insights/actions';
import { loadSocials } from 'services/socials/actions';
import { isOverlayEmbed, displayGDPR } from 'services/user-layout/selectors';
import { ISetGDPR, SET_GDPR } from 'services/user-layout/actions';
import { getUserProfile } from 'services/user-profile/actions';
import { getContractData } from 'services/billing';
import { getDevicePermanentId } from 'services/device/selectors';
import { getPlan } from 'services/billing/selectors';
import {
  postMessageToIframeSDK,
  MaestroIFrameMessages,
  MaestroIFrameEvents,
  EventSource,
  IFrameMessageSource,
} from 'services/iframe';

import warning from 'warning';
import jwt from 'jsonwebtoken';
import { getQuery, removeKeys, replace, deleteQuery } from 'services/app-router';
import { dismissModal, showGDPRModal, showModal, USER_SUBMITTED_GDPR_TERMS, loginModal, showAdminErrorModal, IGDPRSubmissionAction } from 'services/modals/actions';
import { ModalKinds } from 'services/modals/types';
import { IFRAME_SDK_URL, CLIENT_JWT_PUBLIC_KEY } from 'config';

import {
  anonSessionInit,
  ACCOUNT_LOOKUP,
  IAccountLookupAction,
  ILogInAction,
  ILoginResponse,
  ILogInSuccessAction,
  IRegisterUserAction,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_OUT,
  logInFailure,
  logInSuccess,
  logOut,
  OauthProvider,
  REFRESH_USER,
  refreshTokens,
  REGISTER_USER,
  registerUserError,
  setAccountData,
  trackingAccepted,
  UBISOFT_LOG_IN,
  ubisoftLogIn,
  setSuperAdmin,
  checkGate,
  setAccountFound,
  IEmailPasswordInfo,
  logIn,
  UPDATE_USER,
  IUpdateUserAction,
  AMAZON_LOG_IN,
  globalAccountLoginSuccess,
  GLOBAL_ACCOUNTS_LOG_IN_SUCCESS,
  setGlobalAccountAdminSites,
  REDIRECT_GLOBAL_ACCOUNT_TO_SITE,
  IRedirectGlobalAccountToSiteAction,
  redirectGlobalAccountToSite,
  setGlobalAccount,
  IGlobalAccountsLogInSuccessAction,
  resetGlobalAccount,
} from './actions';

import {
  IAccessTokenData,
  IThirdPartyAuthProvider,
  AuthPostMessageActions,
  IAuthPostMessagePayload,
  IGlobalAccountAdminSites,
} from './models';

import {
  bowltvLogoutRedirect,
  clearStoredAuthData,
  clearStoredBowlTvId,
  fetchCredentialsFromExchangeToken,
  fetchNewTokens,
  getStoredAccessToken,
  getStoredBowlTvId,
  getStoredRefreshToken,
  getStoredService,
  getUbisoftTicket,
  ILoginIntentResponse,
  INewTokens,
  isAccessTokenDataNearExpiring,
  IUbisoftResponse,
  loadUbisoftSdk,
  logInWithEmail,
  logInWithToken,
  logoutUbisoftSdk,
  parseDataFromAccessToken,
  performAccountLookup,
  redirectTheWholeDangWindow,
  redirectToOauthLogin,
  registerUserRequest,
  requestUbisoftLoginIntentToken,
  sendSentinelPing,
  setStoredAccessToken,
  setStoredRefreshToken,
  setStoredService,
  getIsSuperAdmin,
  registerInviteIntent,
  registerInviteUser,
  putAccount,
  loadAmazon,
  requestAmazonLoginIntentToken,
  getAmazonCode,
  fetchGlobalAccounts,
  switchAccessToken,
  createJwtToken,
} from './api';

import { GDPR_CURRENT_VERSION, GDPR_STORAGE_KEY, getIsInActivatePage } from './constants';

import {
  getPrimaryToken,
  getRefreshToken,
  getThirdPartyAuthProviders,
  getUserService,
  getUserToken,
  getUserTokenData,
  isBusy,
  isUserLoggedIn,
  isUserAdmin,
  isLoggedIn,
  getGlobalAccountAccessToken,
  getGlobalAccount,
} from './selectors';

import IState from '../state';
import { getInviteSelector } from 'services/invite';
import { isMobileLayout } from 'services/device/selectors';
import { AdminActionEvents } from 'services/admin/models';
import { identify, resetSegmentUser, trackEvent } from 'services/segment-analytics/actions';
import { ISiteTags } from 'services/app/constants';
import { loadSegmentSaga } from 'services/segment-analytics/saga';
import { onSubmitFirstLoginForm, displayTypeform, ITypeFormTypes } from 'services/typeform';
import { clearCart } from 'services/ecommerce';
import { setupShopInfo } from 'services/shopify/saga';
import Plan from 'models/IPlan';
import { persistenceService } from 'services/persistence';
import { isGoogleAnalyticsDisabled } from 'services/feature-gate/selectors';

export const CHECK_TOKEN_AGE_INTERVAL_MS = 1000 * 5; // TODO: Increase from 5 seconds?
const VALID_ORIGIN_REGEX = /^(https:\/\/(ruby|api)\.maestro\.io)|(http:\/\/localhost(:\d+)?)$/;

const createPostMessageJwtChannel = () => eventChannel((emit) => {
  const listener = (event: MessageEvent) => {
    const originIsValid = VALID_ORIGIN_REGEX.test(event.origin);
    const eventIsValid = event.data && typeof event.data.jwt === 'string' &&
      typeof event.data.refreshToken === 'string' && typeof event.data.service === 'string' &&
      event.data.source !== IFrameMessageSource.IFRAME_SDK;

    if (originIsValid && eventIsValid) {
      emit(event.data);
    }
  };
  window.addEventListener('message', listener);
  return () => window.removeEventListener('message', listener);
});

const createPostMessageAuthChannel = () => eventChannel((emit) => {
  const listener = (event: MessageEvent) => {
    const isFromParent = event.source === window.parent && window.parent !== window;
    const eventIsValid = event.data && event.data.action &&
      event.data.source !== IFrameMessageSource.IFRAME_SDK &&
      [
        AuthPostMessageActions.LOGIN,
        AuthPostMessageActions.LOGIN_COMPLETE,
        AuthPostMessageActions.LOGOUT,
      ].includes(event.data.action);
    const isUbisoftLogin = event.data && event.data.action
      && event.data.action === AuthPostMessageActions.UBISOFT_LOGIN;
    const shouldEmit = (isFromParent && eventIsValid) || isUbisoftLogin;
    if (shouldEmit) {
      emit(event.data);
    }
  };
  window.addEventListener('message', listener);
  return () => window.removeEventListener('message', listener);
});


function* checkJwtPostMessageSaga() {
  const jwtChannel: Channel<ILoginResponse> = yield call(createPostMessageJwtChannel);
  while (true) {
    try {
      const response: ILoginResponse = yield take(jwtChannel);
      const loggedIn: boolean = yield select(isUserLoggedIn);
      if (!loggedIn) {
        yield put(logInSuccess({
          accessToken: response.jwt,
          auto: false,
          refreshToken: response.refreshToken,
          service: response.service,
        }));
      }
    } catch (error) {
      // TODO: Log properly
      console.error('auth saga error: checkJwtPostMessageSaga'); // tslint:disable-line no-console
      console.error(error); // tslint:disable-line no-console
    }
  }
}

function* checkAuthPostMessageSaga() {
  const authMessageChannel: Channel<IAuthPostMessagePayload> = yield call(createPostMessageAuthChannel);
  while (true) {
    try {
      const response: IAuthPostMessagePayload = yield take(authMessageChannel);
      const loggedIn: boolean = yield select(isUserLoggedIn);
      if (!loggedIn && response.action === AuthPostMessageActions.LOGIN) {
        yield put(loginModal());
      } else if (loggedIn && response.action === AuthPostMessageActions.LOGOUT) {
        yield put(logOut());
      } else if (response.action === AuthPostMessageActions.LOGIN_COMPLETE) {
        const exchangeToken = response.token;
        yield call(logInWithExchangeTokenSaga, exchangeToken);
      } else if (!loggedIn && response.action === AuthPostMessageActions.UBISOFT_LOGIN) {
        yield put(dismissModal('login'));
        yield put(ubisoftLogIn());
      } else {
        // tslint:disable-next-line no-console
        console.warn(`Cannot process action "${response.action}" while loggedIn = ${loggedIn}`);
      }
    } catch (error) {
      // TODO: Log properly
      console.error('auth saga error: checkAuthPostMessageSaga'); // tslint:disable-line no-console
      console.error(error); // tslint:disable-line no-console
    }
  }
}

export function* superAdminSaga({ payload }: ILogInSuccessAction) {
  const state = yield select();
  const siteId = getSiteId(state);
  const isAdmin = isUserAdmin(state);
  let isSuperAdmin = false;
  if (!(payload && payload.accessToken)) {
    return;
  }
  if(isAdmin) {
    try {
      const response = yield call(getIsSuperAdmin, {
        accessToken: payload.accessToken,
        siteId,
      });
      isSuperAdmin = response.isSuperAdmin;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e, 'error in super admin saga');
    }
  }
  yield put(setSuperAdmin(isSuperAdmin));
}

// persist auth data, then postMessage to parent if necessary
export function* logInSuccessSaga({ payload }: ILogInSuccessAction) {
  let state: IState = yield select();
  const isAdmin = isUserAdmin(state);
  const isMobile = isMobileLayout(state);
  const service = getUserService(state);
  const siteTags = getSiteTags(state);
  const slug: string = getSiteSlug(state) || '';
  const siteid: string = getSiteId(state) || '';

  // clear the bowltv storage if authing with a non-bowl account
  if (service !== OauthProvider.BOWLTV) {
    yield call(clearStoredBowlTvId);
  }
  yield fork(getAccountSaga);

  yield call(setStoredAccessToken, payload.accessToken);
  yield call(setStoredRefreshToken, payload.refreshToken);
  yield call(setStoredService, payload.service);
  yield call(postMessageToIframeSDK, { action: AuthPostMessageActions.LOGIN });

  // dont cache user data for ubisoft
  if (service !== OauthProvider.UBISOFT) {
    yield call(setStoredAccessToken, payload.accessToken);
    yield call(setStoredRefreshToken, payload.refreshToken);
    yield call(setStoredService, payload.service);
  }

  yield call(postMessageToIframeSDK, { action: AuthPostMessageActions.LOGIN });
  yield call(postMessageToIframeSDK, { action: MaestroIFrameMessages.LOGIN_COMPLETE });
  yield put(getUserProfile());
  yield call(setupShopInfo);

  /**
   * Maestro TV Apps Login
   * Show TV Apps activation modal when user is in protected route: "/activate"
   */
  const isInActivatePage = getIsInActivatePage(window.location.pathname);
  if (isInActivatePage) {
    const prefilledLoginCode = window.location.search.split('tv_code=')[1] ?? '';
    yield put(showModal({ kind: ModalKinds.tvAppsLogin, data: { prefilledLoginCode, transparentBackground: isMobile } }));
  }

  if (!isAdmin) {
    // admin actions bellow
    return;
  }
  yield put(getContractData());
  let plan: Plan | null = null;
  let maxAttempts: number = 0;
  while (plan === null && maxAttempts < 5) {
    state = yield select();
    plan = getPlan(state);
    yield delay(1000);
    maxAttempts++;
  }
  yield call(loadSegmentSaga);
  let attempts: number = 0;
  while(attempts < 5) {
    if (window.analytics) {
      yield put(identify({ provider: service ? service : 'email' }));
      yield put(
        trackEvent({
          event: payload.auto || payload.firstLogin
            ? AdminActionEvents.ADMIN_AUTO_LOGIN
            : AdminActionEvents.ADMIN_LOGIN,
          properties: {
            mobile: isMobile,
            firstLogin: !!payload.firstLogin,
          },
        }),
      );
      break;
    }
    yield delay(1000);
    attempts++;
  }

  if (siteTags.includes(ISiteTags.FIRST_ADMIN_LOGIN)) {
    yield put(
      displayTypeform({
        formKey: TYPEFORM_FIRST_SSU_LOGIN_FORM_ID,
        formType: ITypeFormTypes.WIDGET,
        hidden: {
          mobile: isMobile,
          slug,
          siteid,
        },
        inlineOnMobile: true,
        onSubmit: onSubmitFirstLoginForm,
        style: {
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        },
      }),
    );
  }
}

const IS_OWL = SITE_SLUG === 'owl-s2';
const OWL_REDIRECT = IS_OWL && (window.top !== window);


export function* getAccountSaga() {
  const state = yield select();
  const accessToken = getPrimaryToken(state);
  if (!accessToken) {
    yield put(checkGate());
    return;
  }

  try {
    const accountData: IAccessTokenData = yield call(parseDataFromAccessToken, accessToken);
    yield put(setAccountData(accountData));
    yield call(postMessageToIframeSDK, { account: accountData, action: MaestroIFrameMessages.ACCOUNT_SET });
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error in get account saga');
  } finally {
    yield put(checkGate());
  }
}

export function* putAccountSaga(action: IUpdateUserAction) {
  const state = yield select();
  const accessToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  if (!accessToken) {
    yield put(checkGate());
    return;
  }

  try {
    const accountDataResponse = yield call(putAccount, action.payload, accessToken, siteId);
    yield put(setAccountData(accountDataResponse.data));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error in put account saga');
  }
}

export function* logInSaga({ payload }: ILogInAction) {
  const state = yield select();
  const siteId = yield select(getSiteId);
  const deviceId = getDevicePermanentId(state);
  const invite = yield getInviteSelector(state);
  const isMobile = yield select(isMobileLayout);
  try {
    switch (payload.strategy) {
      case 'email': {
        let hasRoleAssigned = false;
        if (payload.provider === 'registerInvite') {
          try {
            const { data } = yield call(registerInviteIntent, {
              inviteId: invite._id,
              provider: payload.provider,
              service: 'maestro',
              siteId,
              email:  payload.email,
            });
            hasRoleAssigned = data.hasRoleAssigned;
          }
          catch(error){
            console.error('Error in invite:', error); // tslint:disable-line no-console
          }
        }

        const response: ILoginResponse = yield call(logInWithEmail, {
          deviceId,
          email: payload.email,
          password: payload.password,
          inviteId: payload.inviteId,
          provider: payload.provider,
        });
        const action = payload.globalAccountAuth ? globalAccountLoginSuccess : logInSuccess;
        yield put(action({
          accessToken: response.jwt,
          auto: false,
          refreshToken: response.refreshToken,
          service: response.service,
          subscriptionCheckoutData: payload.subscriptionCheckoutData,
          firstLogin: !!payload.firstLogin,
        }));

        if (!hasRoleAssigned && payload.provider === 'registerInvite') {
          if (isMobile) {
            yield put(showModal({ kind: ModalKinds.invite, data: { text: 'WARNING_USING_MOBILE_AS_ADMIN' } }));
          } else {
            yield put(showModal({ kind: ModalKinds.invite, data: { text: 'GRANTED_ADMIN' } }));
          }
        }
        if (FORCE_ENABLE_MAESTRO_LOGIN) {
          yield put(replace({ query: { login: undefined } }));
        }
        break;
      }
      case 'oauth':
        yield call(redirectToOauthLogin, payload.provider, {
          redirectRootWindow: OWL_REDIRECT,
          returnUrlOverride: OWL_REDIRECT ? 'https://qa5.overwatchleague.com/en-us/' : undefined,
        });
        break;
      case 'legacy': {
        const { response } = payload;
        yield put(logInSuccess({
          accessToken: response.jwt,
          auto: false,
          refreshToken: response.refreshToken,
          service: response.service,
        }));
        break;
      }
      default: {
        // TODO: Log properly
        // tslint:disable-next-line no-console
        throw new Error(`Unknown logInSaga payload: ${JSON.stringify(payload, null, 2)}`);
      }
    }
  } catch (error) {
    const message: unknown = error && error.message;
    yield put(logInFailure(typeof message === 'string' ? message : 'An error occurred'));
    console.error('Error in logInSaga:'); // tslint:disable-line no-console
    console.error(error); // tslint:disable-line no-console
  }
}

export function* registerSaga(action: IRegisterUserAction) {
  const state = yield select();
  const siteId = yield select(getSiteId);
  const invite = yield getInviteSelector(state);
  const registerPayload = action.payload;
  if (registerPayload.provider === 'registerInvite') {
    try {
      yield call(registerInviteUser, registerPayload, siteId);
    } catch (error) {
      const message: unknown = error?.response?.data;
      yield put(registerUserError(typeof message === 'string' ? message : 'An error occurred'));
      return;
    }
    yield call(registerInviteIntent, {
      inviteId: invite._id,
      provider: 'registerInvite',
      service: 'maestro',
      siteId,
      email: registerPayload.email,
    });

    const loginPayload: IEmailPasswordInfo = {
      email: registerPayload.email,
      password: registerPayload.password,
      strategy: 'email',
      subscriptionCheckoutData: registerPayload.subscriptionCheckoutData,
      provider: registerPayload.provider,
    };
    yield put(logIn(loginPayload));
  } else {
    try {
      const { data } = yield call(registerUserRequest, registerPayload, siteId);
      yield put(logInSuccess({
        accessToken: data.jwt,
        auto: false,
        refreshToken: data.refreshToken,
        service: data.service,
        subscriptionCheckoutData: registerPayload.subscriptionCheckoutData,
      }));
    } catch (error) {
      const message: unknown = error?.response?.data;
      yield put(registerUserError(typeof message === 'string' ? message : 'An error occurred'));
      return;
    }
  }
}

// clear stored auth data, then postMessage to parent if necessary
export function* logOutSaga() {
  if (INVITE_TOKEN) {
    deleteQuery('invite');
  }
  yield call(postMessageToIframeSDK, { action: MaestroIFrameEvents.IFRAME_REQUEST_LOGOUT, eventSource: EventSource.LOGOUT_SAGA });
  const authMap = yield select(getAuthenticationMap);
  if (authMap.uplay) {
    yield call(logoutUbisoftSdk);
  }
  yield call(clearStoredAuthData);
  yield call(postMessageToIframeSDK, { action: AuthPostMessageActions.LOGOUT });
  yield call(postMessageToIframeSDK, { action: MaestroIFrameMessages.LOGOUT_COMPLETE, eventSource: EventSource.LOGOUT_SAGA });

  yield put(clearCart({ clearDbCart: false }));
  // this should only exist if the last auth service was bowl
  // as all other servies will clear this value
  const storedBowlTvId = yield call(getStoredBowlTvId);
  if (storedBowlTvId) {
    yield call(bowltvLogoutRedirect);
  }
  deleteQuery('invite');
  yield put(resetSegmentUser());
}

export function* initAnonSessionIfBusySaga() {
  const busy: boolean = yield select(isBusy);
  if (busy) {
    yield put(anonSessionInit());
  }
}

export function* checkStoredTokenSaga() {
  const state = yield select();
  let accessToken: string | null = yield call(getStoredAccessToken);
  let refreshToken: string | null = yield call(getStoredRefreshToken);
  const service: string | null = yield call(getStoredService);
  const isInActivatePage = getIsInActivatePage(window.location.pathname);

  if (accessToken && refreshToken && service) {
    try {
      const tokenData = yield call(parseDataFromAccessToken, accessToken);
      const shouldRefreshTokens: boolean = yield call(isAccessTokenDataNearExpiring, tokenData);
      if (shouldRefreshTokens) {
        const deviceId = getDevicePermanentId(state);
        warning(false, 'Stored tokens needed refresh');
        const response: INewTokens = yield call(fetchNewTokens, { accessToken, refreshToken, deviceId });
        ({ accessToken, refreshToken } = response);
      }
      yield put(logInSuccess({
        accessToken,
        auto: true,
        refreshToken,
        service,
      }));
      if (FORCE_ENABLE_MAESTRO_LOGIN) {
        yield put(replace({ query: { login: undefined } }));
      }
    } catch (error) {
      console.error('auth saga error: checkStoredTokenSaga:'); // tslint:disable-line no-console
      console.error(error); // tslint:disable-line no-console
    }
  } else if(FORCE_ENABLE_MAESTRO_LOGIN || isInActivatePage) {
    yield put(loginModal());
  }

  yield call(initAnonSessionIfBusySaga);
}

export function* logInWithExchangeTokenSaga(exchangeToken: string) {
  try {
    if (yield select(isUserLoggedIn)) {
      yield put(logOut());
    }
    const creds: ILoginResponse = yield call(fetchCredentialsFromExchangeToken, exchangeToken);
    yield put(logInSuccess({
      accessToken: creds.jwt,
      auto: false,
      refreshToken: creds.refreshToken,
      service: creds.service,
    }));
  } catch (e) {
    throw e;
  } finally {
    yield put(removeKeys(['exchangeToken']));
  }
}

export function* logInWithEmailAndPasswordFromQueryParam(token: string) {
  try {
    const { email, password } = jwt.verify(token, CLIENT_JWT_PUBLIC_KEY) as {
      email: string;
      password: string;
    };
    const loginPayload: IEmailPasswordInfo = {
      email,
      password,
      strategy: 'email',
      firstLogin: true,
    };
    yield put(logIn(loginPayload));
  } catch (error) {
    yield put(logInFailure('Unable to login, please try again.'));
  }
}

export function* logInWithAccessTokenFromQueryParam(token: string) {
  try {
    const { accessToken, refreshToken, service } = jwt.verify(token, CLIENT_JWT_PUBLIC_KEY) as {
      accessToken: string;
      refreshToken: string;
      service: string;
    };

    yield put(logInSuccess({
      accessToken,
      auto: false,
      refreshToken,
      service,
      firstLogin: false,
    }));
  } catch (error) {
    yield put(logInFailure('Unable to login, please try again.'));
  }
}

export function* initLoginSaga() {
  try {
    const state = (yield select()) as IState;
    const query = getQuery(state);
    const exchangeToken: string | undefined = query.exchangeToken as string | undefined;
    const token: string | undefined = query.inline_login as string | undefined;
    const autoLoginToken: string | undefined = query.auto_login as string | undefined;

    if (exchangeToken) {
      yield put(replace({ query: { token: undefined! } }));
      yield call(logInWithExchangeTokenSaga, exchangeToken);
    } if (token) {
      yield call(logInWithEmailAndPasswordFromQueryParam, token);
    } if (autoLoginToken) {
      yield call(logInWithAccessTokenFromQueryParam, autoLoginToken);
    } else {
      yield call(checkStoredTokenSaga);
    }
  } catch (error) {
    // TODO: log properly
    console.error('Error initializing login'); // tslint:disable-line no-console
    console.error(error); // tslint:disable-line no-console
  } finally {
    yield call(initAnonSessionIfBusySaga);
    if (POST_AUTH_REDIRECT) {
      yield call(redirectTheWholeDangWindow, { url: POST_AUTH_REDIRECT });
    }
  }
}

export function* resetPasswordSaga() {
  try {
    const state = (yield select()) as IState;
    const query = getQuery(state);
    const resetToken: string | undefined = query.reset_token as string | undefined;
    const gaResetToken: string | undefined = query.ga_reset_token as string | undefined;
    if (resetToken) {
      // yield put(replace({ query: { token: undefined! } }, { persistQuery: true }));  TODO uncomment!!!!!!!!!!!1
      yield put(showModal({
        data: {
          token: resetToken,
        },
        kind: ModalKinds.resetPassword,
      }));
    } else if (gaResetToken) {
      yield put(showModal({
        data: {
          ga_token: gaResetToken,
        },
        kind: ModalKinds.resetPassword,
      }));
    }
  } catch (e) {
    console.error('Error in reset saga: '); // tslint:disable-line no-console
    console.error(e); // tslint:disable-line no-console
  }
}

export function* sentinelSaga() {
  while (true) {
    const tokenData = yield select(getThirdPartyAuthProviders);
    const battlenetAccount = find(tokenData, ({ provider }: IThirdPartyAuthProvider) => (
      provider === 'battlenet'
    )) as IThirdPartyAuthProvider | null;
    if (battlenetAccount) {
      try {
        yield call(sendSentinelPing, battlenetAccount.accountId);
      } catch (error) {
        // TODO: log properly
        console.error(error); // tslint:disable-line no-console
      }
    }
    // ping every minute
    yield delay(1000 * 60);
  }
}

export function* keepTokensRefreshedSaga() {
  while (true) {
    const state = yield select();
    const deviceId = getDevicePermanentId(state);
    try {
      yield delay(CHECK_TOKEN_AGE_INTERVAL_MS);
      const tokenData: IAccessTokenData | null = yield select(getUserTokenData);
      if (!tokenData) {
        continue;
      }
      const shouldRefreshTokens: boolean = yield call(isAccessTokenDataNearExpiring, tokenData);
      if (shouldRefreshTokens) {
        warning(false, 'Interval detected old token. Refreshing');
        const previousRefreshToken: string = yield select(getRefreshToken);
        const { accessToken, refreshToken }: INewTokens = yield call(fetchNewTokens, {
          accessToken: yield select(getUserToken),
          refreshToken: previousRefreshToken,
          deviceId,
        });
        yield put(refreshTokens({
          accessToken,
          previousRefreshToken,
          refreshToken,
        }));
      }
    } catch (error) {
      // TODO: Log properly
      console.error('auth saga error: checkJwtPostMessageSaga'); // tslint:disable-line no-console
      console.error(error); // tslint:disable-line no-console
    }
  }
}

export function* consentToTracking() {
  const googleAnalyticsDisabled = yield select(isGoogleAnalyticsDisabled);
  yield put(trackingAccepted());
  if (!googleAnalyticsDisabled) {
    yield put(loadGA());
  }
  yield put(loadSocials());
}

export function* isGdprAccepted() {
  const acceptedVersion =  Number.parseFloat(yield call(persistenceService().read, GDPR_STORAGE_KEY));
  /**
   * If the user has REJECTED any version of the GDPR terms, return false.
   */
  if (acceptedVersion === -1) {
    return false;
  }
  return acceptedVersion === GDPR_CURRENT_VERSION;
}

export const gdprSaga = function* (action?: ISetGDPR) {
  const overlayEmbedActive = yield select(isOverlayEmbed);
  const showGDPR = yield select(displayGDPR);
  const shouldRenderGDPRModal = Number.isNaN(parseFloat(yield call(persistenceService().read, GDPR_STORAGE_KEY)));
  if (overlayEmbedActive) {
    return;
  }

  if (shouldRenderGDPRModal) {
    yield put(showGDPRModal());
  }

  if (IFRAME_SDK_URL === 'iframe') {
    while (true) {
      if (action?.type !== SET_GDPR) {
        yield take(SET_GDPR);
      }

      if (yield call(isGdprAccepted)) {
        yield call(consentToTracking);
      }

      if (action?.type === SET_GDPR) {
        return;
      }
    }
  }


  if ((NO_GDPR || !showGDPR) || (yield call(isGdprAccepted))) {
    yield call(consentToTracking);
  }
};

export const handleUserGdprSubmission = function* ({ payload }: IGDPRSubmissionAction) {
  const { accepted = true } = payload;
  yield put(dismissModal('gdpr'));
  if (!accepted) {
    yield call(persistenceService().write, GDPR_STORAGE_KEY, -1);
    return;
  }
  yield call(persistenceService().write, GDPR_STORAGE_KEY, GDPR_CURRENT_VERSION);
  yield call(consentToTracking);
};



export function* amazonLogInSaga() {
  const state = yield select();
  const siteId = getSiteId(state);
  const deviceId = getDevicePermanentId(state);
  const code = yield call(getAmazonCode, siteId);
  const intentResp: ILoginIntentResponse = yield call(requestAmazonLoginIntentToken, {
    code,
    siteId,
  });
  const creds: ILoginResponse = yield call(logInWithToken, {
    siteId,
    token: intentResp.token,
    deviceId,
  });
  yield put(logInSuccess({
    accessToken: creds.jwt,
    auto: false,
    refreshToken: creds.refreshToken,
    service: creds.service,
  }));
}

export function* ubisoftLogInSaga() {
  const state = yield select();
  const siteId = getSiteId(state);
  const deviceId = getDevicePermanentId(state);
  const payload: IUbisoftResponse = yield call(getUbisoftTicket);
  if (!payload) {
    return;
  }
  const intentResp: ILoginIntentResponse = yield call(requestUbisoftLoginIntentToken, {
    sessionId: payload.sessionId,
    siteId,
    ticket: payload.ticket,
  });
  const creds: ILoginResponse = yield call(logInWithToken, {
    siteId,
    token: intentResp.token,
    deviceId,
  });
  yield put(logInSuccess({
    accessToken: creds.jwt,
    auto: false,
    refreshToken: creds.refreshToken,
    service: creds.service,
  }));
}

export const initializeUbisoftSaga = function* () {
  const state = yield select();
  const authMap = getAuthenticationMap(state);
  if (authMap.uplay) {
    yield call(loadUbisoftSdk);
    // if user is already logged in calling this will complete the process
    yield fork(ubisoftLogInSaga);
  }
};

export const initializeAmazonSaga = function* () {
  const state = yield select();
  const authMap = getAuthenticationMap(state);
  if (authMap.amazon) {
    yield call(loadAmazon);
  }
};

export const accountLookupSaga = function* ({ payload: { email, globalAccountAuth } } : IAccountLookupAction): any {
  const state = yield select();
  const siteId = getSiteId(state);
  const token = jwt.sign({
    data: `${siteId}/${Date.now()}/${Math.random().toString(36).substring(2, 12)}`,
  }, CLIENT_JWT_PUBLIC_KEY);
  try {
    const result = yield(call(performAccountLookup, { email, siteId, token }));
    yield(put(setAccountFound({ isAccountFound: true, email: result.email, userName: result.name })));
  } catch (error) {
    if (error?.response?.status !== 404) {
      return yield put(showAdminErrorModal('An error occurred while looking up the account. Please try again later.'));
    }
    if (globalAccountAuth) {
      yield put(dismissModal());
      yield put(showModal({ kind: ModalKinds.selfServiceSignup, data: { email, locked: true } }));
      return;
    }

    yield(put(setAccountFound({ isAccountFound: false })));
  }
};

export const routeChangeSaga = function* () {
  const state = (yield select()) as IState;
  const query = getQuery(state);
  const userLoggedIn = isLoggedIn(state);

  if (userLoggedIn && query.inline_login) {
    deleteQuery('inline_login');
  }

  if (userLoggedIn && query.auto_login) {
    deleteQuery('auto_login');
  }
};

export function* globalAccountsLoginSuccessSaga({ payload: { accessToken } }: IGlobalAccountsLogInSuccessAction) {
  const token: IAccessTokenData = yield call(parseDataFromAccessToken, accessToken);
  const { siteId } = token;
  if (!siteId) {
    return;
  }

  yield put(setGlobalAccount(token));
  const response: IGlobalAccountAdminSites[] = yield call(fetchGlobalAccounts, {
    accessToken,
    siteId,
  });

  if (!response.length) {
    yield put(dismissModal(ModalKinds.login));
    yield put(resetGlobalAccount());
    yield put(showModal({ kind: ModalKinds.selfServiceSignup, data: { email: token.email || '', locked: true } }));
    return;
  }

  if (response.length === 1) {
    yield put(
      redirectGlobalAccountToSite({
        siteId: response[0].siteId,
        siteSlug: response[0].slug,
      }),
    );
    return;
  }

  // sort alphabetically
  const sortedResponse = response
  .slice()
  .sort((a: IGlobalAccountAdminSites, b: IGlobalAccountAdminSites) => {
    return a.slug.localeCompare(b.slug);
  });

  yield put(setGlobalAccountAdminSites(sortedResponse));
}

export function* redirectGlobalAccountToSiteSaga({ payload: { siteId, siteSlug } }: IRedirectGlobalAccountToSiteAction) {
  const state: IState = yield select();
  const globalAccountAccessToken = getGlobalAccountAccessToken(state);
  const account = getGlobalAccount(state);
  if (!account || !account.siteId || !siteId) {
    return;
  }

  try {
    const response: ILoginResponse = yield call(switchAccessToken, {
      accessToken: globalAccountAccessToken!,
      fromSiteId: account.siteId!,
      toSiteId: siteId,
    });

    const token: string = yield call(createJwtToken, {
      payload: {
        accessToken: response.jwt,
        refreshToken: response.refreshToken,
        service: response.service,
      },
      secretOrPrivateKey: CLIENT_JWT_PUBLIC_KEY,
      options: { expiresIn: '10m' },
    });

    const redirectSite = `${window.location.origin}/${siteSlug}?auto_login=${token}`;
    yield call(redirectTheWholeDangWindow, { url: redirectSite });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log('error in redirectUserToSiteSaga', error);
  }
}


export default function* authSaga() {
  yield takeEvery(LOG_IN_SUCCESS, logInSuccessSaga);
  yield takeEvery(LOG_IN_SUCCESS, superAdminSaga);
  yield takeEvery(GLOBAL_ACCOUNTS_LOG_IN_SUCCESS, globalAccountsLoginSuccessSaga);
  yield takeEvery(REDIRECT_GLOBAL_ACCOUNT_TO_SITE, redirectGlobalAccountToSiteSaga);
  yield takeEvery(LOG_IN, logInSaga);
  yield takeEvery(LOG_OUT, logOutSaga);
  yield takeEvery(REGISTER_USER, registerSaga);
  yield takeEvery(USER_SUBMITTED_GDPR_TERMS, handleUserGdprSubmission);
  yield takeEvery(AMAZON_LOG_IN, amazonLogInSaga);
  yield takeEvery(UBISOFT_LOG_IN, ubisoftLogInSaga);
  yield takeEvery(REFRESH_USER, getAccountSaga);
  yield takeEvery(UPDATE_USER, putAccountSaga);
  yield takeEvery(SET_GDPR, gdprSaga);
  yield takeEvery(ACCOUNT_LOOKUP, accountLookupSaga);
  yield takeEvery(LOCATION_CHANGE, routeChangeSaga);
  yield fork(checkJwtPostMessageSaga);
  yield fork(sentinelSaga);
  yield fork(checkAuthPostMessageSaga);
  yield fork(keepTokensRefreshedSaga);
  yield fork(gdprSaga);
  yield call(initLoginSaga);
  yield call(resetPasswordSaga);
  yield call(initializeUbisoftSaga);
  yield call(initializeAmazonSaga);
}
