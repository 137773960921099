import { put, call, delay, takeEvery, select, fork } from 'redux-saga/effects';
import { openPanel } from 'services/custom-panels/actions';
import axios from 'axios';
import shortid from 'shortid';
import { msIn } from 'shared/datetime-utils';
import {
  findQuestPanel,
  FIND_QUEST_PANEL,
  setActivePanel,
  setTimeOffset,
} from './actions';
import { getPanelIds } from './selectors';
import { makeSaga } from 'redux-utils';

const TIME_URL = 'https://insights.maestro.io/time';

export const getDeviceOffset = async () => {
  const responseStart = Date.now();
  const { data: { time: serverTime, diff: serverOffset } } = await axios.get(TIME_URL, {
    params: {
      _: `${Date.now()}-${shortid.generate()}`,
      client_time: responseStart,
    },
  });

  const responseEnd = Date.now();

  const queryTime = responseEnd - responseStart;
  const clientOffset = responseEnd - serverTime;

  // from NTP algo: https://en.wikipedia.org/wiki/Network_Time_Protocol
  // JS example: https://stackoverflow.com/questions/1638337
  // /the-best-way-to-synchronize-client-side-javascript-clock-with-server-date
  // will account for latent response times back to client
  return (serverOffset - queryTime - clientOffset) / 2;
};

export const findQuestPanelSaga = makeSaga(
  { findQuestPanel },
  function* ({ payload }: any) {
    const questIdToFind = payload?._id;
    if (!questIdToFind) {
      return;
    }

    const state = yield select();
    const { realtime: { documents }, customPanels } = state;
    const panelIds = getPanelIds(state);
    const matchingQuestPanelId = panelIds.find((id) => {
      const doc = documents[`objects/${id}`];
      if (doc.renderer.panelType === 'quests') {
        return doc.renderer.quests.find(quest => quest.questId === questIdToFind);
      }
      return false;
    });
    const matchingQuestPanel = documents[`objects/${matchingQuestPanelId}`];
    if (customPanels.length > 0) {
      if (!matchingQuestPanel) {
        return;
      }
      yield put(openPanel({ doc: matchingQuestPanel }));
    } else {
      if (!matchingQuestPanelId) {
        return;
      }
      yield put(setActivePanel(matchingQuestPanelId));
    }
  },
);

export const serverOffsetSaga = function* () {
  while (true) {
    try {
      const offset = yield call(getDeviceOffset);
      yield put(setTimeOffset(offset));
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
    } finally {
      yield delay(msIn.minute);
    }
  }
};

const appSaga = function* () {
  yield takeEvery(FIND_QUEST_PANEL, findQuestPanelSaga);
  yield fork(serverOffsetSaga);
};

export default appSaga;
